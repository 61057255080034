import https from "./https";

export const getPublicKey = () => { return https.doGet("user-server/nk/manager/v1/key") };

export const getCaptcha = params => { return https.doGet("user-server/nk/user/v1/captcha", params) };
export const postRegisterCode = params => { return https.doPost("user-server/nk/user/v2/register_code", params) };
export const postRegister = params => { return https.doPost("user-server/nk/user/v1/register", params) };

export const getAddressCount = () => { return https.doGet("member-server/yk/user/v1/address/count") };
export const getAddress = () => { return https.doGet("member-server/yk/user/v1/address/info") };
export const postAddressAdd = params => { return https.doPost("member-server/yk/user/v1/address/add", params) };
export const postAddressEdit = params => { return https.doPost("member-server/yk/user/v1/address/edit", params) };
export const postAddressDel = params => { return https.doPost("member-server/yk/user/v1/address/del", params) };

export const getAwardItems = () => { return https.doGet("member-server/yk/award/v1/items") };
export const getLuckDraw = () => { return https.doGet("member-server/yk/award/v1/luck/draw") };
export const getLuckDrawItems = () => { return https.doGet("member-server/yk/award/v1/draw/notes") };
